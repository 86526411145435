<template>
  <div class="column">
    <img
      class="top_image"
      src="../../assets/img/home/home/PES/Pic_Search.png"
    />
    <div class="row center mt5">
      <div class="left" @click="search">
        <img
          class="search"
          src="../../assets/img/home/home/PES/Icon_Search.png"
        />
      </div>
      <div class="right row_center" @click="clickInput">
        <div class="line_v"></div>
        <div class="search_text">幼儿测试数据天眼搜索</div>
      </div>
    </div>
    <div class="row_base mt18" v-if="showSearch">
      <div class="column_center">
        <div class="row cur" @click="clickZb">
          <div class="left1 column_center">
            <img
              v-if="zbShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right1 column_center">
            <div class="label_text">指标</div>
          </div>
        </div>
        <div v-if="zbShow">
          <div
            v-for="(item, index) in zbList"
            @click="clickZbItem(item)"
            :key="item.id"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentZbId == item.id" class="zb_select"></div>
              <div v-else class="zb_normal"></div>
              <div v-if="currentZbId == item.id" class="zb_select_text">
                {{ item.name }}
              </div>
              <div v-else class="zb_normal_text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column_center mrl5">
        <div class="row cur" @click="clickDq">
          <div class="left2 column_center">
            <img
              v-if="dqShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right2 column_center">
            <div class="label_text">地区</div>
          </div>
        </div>
        <div v-if="dqShow">
          <div
            v-for="(item, index) in dqList"
            @click="clickDqItem(item)"
            :key="item.value"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentDqId == item.value" class="dq_select"></div>
              <div v-else class="dq_normal"></div>
              <div v-if="currentDqId == item.value" class="dq_select_text">
                {{ item.label }}
              </div>
              <div v-else class="zb_normal_text">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column_center mrl5">
        <div class="row cur" @click="clickZq">
          <div class="left3 column_center">
            <img
              v-if="zqShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right3 column_center">
            <div class="label_text">周期</div>
          </div>
        </div>
        <div v-if="zqShow">
          <div
            v-for="(item, index) in zqList"
            @click="clickZqItem(item)"
            :key="item.id"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentZqId == item.id" class="zq_select"></div>
              <div v-else class="zq_normal"></div>
              <div v-if="currentZqId == item.id" class="zq_select_text">
                {{ item.name }}
              </div>
              <div v-else class="zb_normal_text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column_center mrl5">
        <div class="row cur" @click="clickXb">
          <div class="left4 column_center">
            <img
              v-if="xbShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right4 column_center">
            <div class="label_text">性别</div>
          </div>
        </div>
        <div v-if="xbShow">
          <div
            v-for="(item, index) in xbList"
            @click="clickXbItem(item)"
            :key="item.id"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentXbId == item.id" class="xb_select"></div>
              <div v-else class="xb_normal"></div>
              <div v-if="currentXbId == item.id" class="xb_select_text">
                {{ item.name }}
              </div>
              <div v-else class="zb_normal_text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column_center mrl5">
        <div class="row cur" @click="clickNl">
          <div class="left5 column_center">
            <img
              v-if="nlShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right5 column_center">
            <div class="label_text">年龄</div>
          </div>
        </div>
        <div v-if="nlShow">
          <div
            v-for="(item, index) in nlList"
            @click="clickNlItem(item)"
            :key="item.id"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentNlId == item.id" class="nl_select"></div>
              <div v-else class="nl_normal"></div>
              <div v-if="currentNlId == item.id" class="nl_select_text">
                {{ item.name }}
              </div>
              <div v-else class="zb_normal_text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column_center mrl5">
        <div class="row cur" @click="clickSz">
          <div class="left6 column_center">
            <img
              v-if="szShow"
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_Down_W.png"
            />
            <img
              v-else
              class="arrow"
              src="../../assets/img/home/home/PES/Icon_Arrow_R_W.png"
            />
          </div>
          <div class="right6 column_center">
            <div class="label_text">数值</div>
          </div>
        </div>
        <div v-if="szShow">
          <div
            v-for="(item, index) in szList"
            @click="clickSzItem(item)"
            :key="item.id"
          >
            <div class="row_center mt5 cur">
              <div v-if="currentSzId == item.id" class="sz_select"></div>
              <div v-else class="sz_normal"></div>
              <div v-if="currentSzId == item.id" class="sz_select_text">
                {{ item.name }}
              </div>
              <div v-else class="zb_normal_text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h240"></div>
  </div>
</template>
<script>
export default {
  name: "childrenCX",
  components: {},
  data() {
    return {
      showSearch: false,
      zbShow: false,
      dqShow: false,
      zqShow: false,
      xbShow: false,
      nlShow: false,
      szShow: false,
      currentZbId: "",
      currentZbName: "",
      testNo: "",
      zbList: [
        { id: 1, name: "身高", no: "A" },
        { id: 2, name: "体重", no: "B" },
        { id: 3, name: "胸围", no: "F" },
        { id: 4, name: "视力", no: "C" },
        { id: 5, name: "臂展", no: "D" },
        { id: 6, name: "体前屈", no: "H" },
        { id: 7, name: "肺活量", no: "E" },
        { id: 8, name: "跑动", no: "A" },
        { id: 9, name: "跳跃", no: "B" },
        { id: 10, name: "平衡", no: "D" },
        { id: 11, name: "力量", no: "F" },
        { id: 12, name: "敏捷", no: "C" },
        { id: 13, name: "协调", no: "E" },
        { id: 14, name: "物控手", no: "G" },
        { id: 15, name: "物控脚", no: "H" },
      ],
      currentDqId: -1,
      currentDqName: "",
      dqList: [{ value: -1, label: "全国" }],
      currentZqId: "",
      currentZqName: "",
      zqList: [],
      currentXbId: 0,
      currentXbName: "",
      xbList: [
        { id: 0, name: "全部" },
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      currentNlId: 0,
      currentNlName: "",
      nlList: [
        { id: 0, name: "全部" },
        { id: 3, name: "3岁" },
        { id: 4, name: "4岁" },
        { id: 5, name: "5岁" },
        { id: 6, name: "6岁" },
      ],
      currentSzId: 1,
      szList: [
        { id: 1, name: "平均值" },
        { id: 2, name: "完成率" },
        { id: 3, name: "增长率" },
        { id: 4, name: "综合评分" },
      ],
    };
  },

  methods: {
    clickInput() {
      this.showSearch = true;
    },
    clickZb() {
      this.zbShow = !this.zbShow;
    },
    clickDq() {
      this.dqShow = !this.dqShow;
    },
    clickZq() {
      this.zqShow = !this.zqShow;
    },
    clickXb() {
      this.xbShow = !this.xbShow;
    },
    clickNl() {
      this.nlShow = !this.nlShow;
    },
    clickSz() {
      this.szShow = !this.szShow;
    },
    clickZbItem(item) {
      this.currentZbId = item.id;
      this.currentZbName = item.name;
      this.testNo = item.no;
    },
    clickDqItem(item) {
      this.currentDqId = item.value;
      this.currentDqName = item.label;
    },
    clickZqItem(item) {
      this.currentZqId = item.id;
      this.currentZqName = item.name;
    },
    clickXbItem(item) {
      this.currentXbId = item.id;
      this.currentXbName = item.name;
    },
    clickNlItem(item) {
      this.currentNlId = item.id;
      this.currentNlName = item.name;
    },
    clickSzItem(item) {
      if (item.id != 1) {
        this.$notice({ message: "暂未开放" }).isShow();
      } else {
        this.currentSzId = item.id;
      }
    },
    // 获取地区列表
    getAreas() {
      this.api.datav.cascaded().then((res) => {
        console.log(res);
        this.dqList.push(...res.data);
      });
    },
    search() {
      if (!this.currentZbId) {
        this.$notice({ message: "请选择指标" }).isShow();
        return;
      }
      this.getData();
    },
    // 获取数据
    getData() {
      let data = {
        testType: this.currentZbId <= 7 ? 2 : 1,
        testNo: this.testNo,
        province: this.currentDqId != -1 ? this.currentDqId : "",
        month: this.currentZqId,
        age: this.currentNlId != 0 ? this.currentNlId : "",
        sex: this.currentXbId != 0 ? this.currentXbId : "",
      };
      this.api.pes.getCXReport(data).then((res) => {
        var message =
          this.currentZbName +
          "  " +
          this.currentDqName +
          "  " +
          this.currentZqName +
          "  " +
          this.currentXbName +
          " " +
          this.currentNlName +
          " " +
          "平均值：" +
          res.data.score +
          res.data.unit;
        this.$notice({ type: "success", message: message }).isShow();
      });
    },
  },
  mounted() {
    this.getAreas();
    let month = new Date().getMonth();
    for (let i = 1; i <= month + 1; i++) {
      var name = "";
      if (i < 10) {
        name = "0" + i + "月";
      } else {
        name = i + "月";
      }
      this.zqList.push({ id: i, name: name });
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
}
.top_image {
  width: 772px;
  height: 260px;
  margin-top: 7px;
}
.row {
  display: flex;
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex {
  flex: 1;
}
.mt50 {
  margin-top: 50px;
}
.column_base {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.title {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
}
.mt5 {
  margin-top: 5px;
}
.left {
  width: 148px;
  height: 48px;
  background: #009eac;
  border: 3px solid #00bdce;
  border-radius: 26px 0px 0px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.search {
  width: 36px;
  height: 36px;
}
.right {
  width: 620px;
  height: 48px;
  border-top: 3px solid #00bdce;
  border-right: 3px solid #00bdce;
  border-bottom: 3px solid #00bdce;
  border-radius: 0px 26px 26px 0px;
  cursor: pointer;
}
.line_v {
  width: 2px;
  height: 36px;
  background: #00bdce;
  border-radius: 1px;
  margin-left: 43px;
}
.search_text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999a9b;
  margin-left: 18px;
}
.mt18 {
  margin-top: 18px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left1 {
  width: 36px;
  height: 26px;
  background: #009eac;
  border: 2px solid #00bdce;
  border-radius: 15px 0px 0px 15px;
}
.arrow {
  width: 26px;
  height: 16px;
}
.cur {
  cursor: pointer;
}
.right1 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #00bdce;
  border-right: 2px solid #00bdce;
  border-bottom: 2px solid #00bdce;
  border-radius: 0px 15px 15px 0px;
}
.label_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cccccc;
}
.mrl5 {
  margin-left: 5px;
}
.left2 {
  width: 36px;
  height: 26px;
  background: #8a000d;
  border: 2px solid #ba0000;
  border-radius: 15px 0px 0px 15px;
}
.right2 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #ba0000;
  border-right: 2px solid #ba0000;
  border-bottom: 2px solid #ba0000;
  border-radius: 0px 15px 15px 0px;
}
.left3 {
  width: 36px;
  height: 26px;
  background: #b08d00;
  border: 2px solid #ebbc00;
  border-radius: 15px 0px 0px 15px;
}
.right3 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #ebbc00;
  border-right: 2px solid #ebbc00;
  border-bottom: 2px solid #ebbc00;
  border-radius: 0px 15px 15px 0px;
}
.left4 {
  width: 36px;
  height: 26px;
  background: #85b000;
  border: 2px solid #b1eb00;
  border-radius: 15px 0px 0px 15px;
}
.right4 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #b1eb00;
  border-right: 2px solid #b1eb00;
  border-bottom: 2px solid #b1eb00;
  border-radius: 0px 15px 15px 0px;
}
.left5 {
  width: 36px;
  height: 26px;
  background: #00bb6e;
  border: 2px solid #00eb8a;
  border-radius: 15px 0px 0px 15px;
}
.right5 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #00eb8a;
  border-right: 2px solid #00eb8a;
  border-bottom: 2px solid #00eb8a;
  border-radius: 0px 15px 15px 0px;
}
.left6 {
  width: 36px;
  height: 26px;
  background: #b30069;
  border: 2px solid #eb008a;
  border-radius: 15px 0px 0px 15px;
}
.right6 {
  width: 58px;
  height: 26px;
  border-top: 2px solid #eb008a;
  border-right: 2px solid #eb008a;
  border-bottom: 2px solid #eb008a;
  border-radius: 0px 15px 15px 0px;
}
.row_base {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.zb_normal {
  width: 10px;
  height: 10px;
  background: #009eac;
  border: 2px solid #00bdce;
  border-radius: 50%;
}

.zb_normal_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cccccc;
  margin-left: 5px;
  max-width: 40px;
}
.zb_select {
  width: 10px;
  height: 10px;
  background: #005f67;
  border: 2px solid #00eaff;
  border-radius: 50%;
  max-width: 40px;
}
.zb_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #005f67;
  margin-left: 5px;
  max-width: 40px;
}
.dq_normal {
  width: 10px;
  height: 10px;
  background: #8a000d;
  border: 2px solid #ba0000;
  border-radius: 50%;
}
.dq_select {
  width: 10px;
  height: 10px;
  background: #5f0009;
  border: 2px solid #ff0000;
  border-radius: 50%;
}
.dq_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5f0009;
  margin-left: 5px;
  max-width: 40px;
}
.zq_normal {
  width: 10px;
  height: 10px;
  background: #b08d00;
  border: 2px solid #ebbc00;
  border-radius: 50%;
}
.zq_select {
  width: 10px;
  height: 10px;
  background: #7e6500;
  border: 2px solid #ffd52b;
  border-radius: 50%;
}
.zq_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #7e6500;
  margin-left: 5px;
  max-width: 40px;
}
.xb_normal {
  width: 10px;
  height: 10px;
  background: #85b000;
  border: 2px solid #b1eb00;
  border-radius: 50%;
}
.xb_select {
  width: 10px;
  height: 10px;
  background: #b1eb00;
  border: 2px solid #ffd52b;
  border-radius: 50%;
}
.xb_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b1eb00;
  margin-left: 5px;
  max-width: 40px;
}
.nl_normal {
  width: 10px;
  height: 10px;
  background: #00bb6e;
  border: 2px solid #00eb8a;
  border-radius: 50%;
}
.nl_select {
  width: 10px;
  height: 10px;
  background: #00eb8a;
  border: 2px solid #ffd52b;
  border-radius: 50%;
}
.nl_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00eb8a;
  margin-left: 5px;
  max-width: 40px;
}
.sz_normal {
  width: 10px;
  height: 10px;
  background: #b30069;
  border: 2px solid #eb008a;
  border-radius: 50%;
}
.sz_select {
  width: 10px;
  height: 10px;
  background: #eb008a;
  border: 2px solid #ffd52b;
  border-radius: 50%;
}
.sz_select_text {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #eb008a;
  margin-left: 5px;
  max-width: 40px;
}
.h240 {
  height: 120px;
}
</style>